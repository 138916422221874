module.exports = {
  error: 'Error',
  tips: 'Tips',
  hotline: 'Hotline ',
  deleteAccount: 'Delete Account',
  deleteAccountTip: 'Are you sure you want to delete this account? Deleting the account will clear all data！',
  subscribeSuccess: 'Subscribed !',
  previousPage: 'Previous Page',
  nextPage: 'Next Page',
  message: 'Please leave your message here. Our customer service officer will get back to you very soon. ',
  contactInformation: 'Contact Information',
  messageSuccess: 'Submitted',
  messageError: 'Please input contact information',
  calendar: {
    mon: 'Mon',
    tues: 'Tues',
    wed: 'Wed',
    thur: 'Thur',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
    year: '/',
    month: '',
    day: 'Day',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesdday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  navbar: {
    index: 'bnbvet',
    home: 'Homepage',
    about: 'About Us',
    expert: 'Expert Team',
    service: 'FAQs ',
    petstory: 'Pet’s Story',
    media: 'Articles ',
    contact: 'Chat With Us ',
    reserve: 'Get Started',
    login: 'Log In',
    signup: 'Sign Up',
    myreserve: 'My Bookings ',
    myinfo: 'Personal Profile ',
    mypet: 'My Pets ',
    notice: 'Notices ',
    logout: 'Log Out',
  },
  tabbar: {
    home: 'Home',
    doctor: 'Doctors',
    member: 'Member',
    pets: 'Pets',
    bookings: 'Bookings',
    lang: 'Change Language',
  },
  login: {
    title: 'Welcome Back To BnBVet',
    title2: 'Welcome To Register BnBVet',
    title3: 'Reset Password',
    email: 'Email Address ',
    phone: 'Phone Number ',
    username: 'User Name ',
    password: ' Password',
    password2: 'Confirm Password ',
    signnow: 'Sign In Now ',
    signed: 'Signed Up',
    logged: 'Logged In',
    remember: 'Remember',
    iaccept: 'I Accept',
    disclaimer: 'Disclaimer ',
    loginsuccessful: 'Login Successful ',
    confirm: 'Confirm',
    cancel: 'Cancel',
    signupnow: 'Sign Up Now',
    loginnow: 'Login Now',
    forgot: 'Forgot your password?',
    signup: 'Sign Up',
    already: '',
    verifycode: 'Verification code will be sent to your email',
    getverifycode: 'Get verification code',
    tipstext: 'A verification code has been sent！',
    success: 'Success！',
    reset: 'Reset ',
    new: 'New',
    logout: 'Sign Out ',
  },
  home: {
    banner: {
      title: 'At home, at ease.',
      content: 'Online consultation',
      btn1: 'Get Started',
    },
    industry: {
      service: 'Video caring service for pets ',
      veterinarian: 'Participating veteranarian globally ',
      education: 'Developed market accredited veterinary degree ',
      satisfaction: 'Customer satisfaction',
    },
    why_choose: {
      title: 'Why Choose BnBVet',
      bnbvet: 'BnBVet',
      btn: 'Get Started',
      box1: {
        title: 'No hassle. At home, at ease ',
        content1: '24h video care ',
        content2: 'Take control of your pet\'s health ',
      },
      box2: {
        title: ' Hong Kong licensed veterinarian, video consultation only',
        content1: '100% developed market certified qualifications',
        content2: '1-to-1 veterinary online service ',
      },
    },
    veterinary_team: {
      title: 'Our Vets ',
      directions1: 'Unlike veterinarians who have not been trained in veterinary medicine abroad',
      directions2: '100% of the vet on the bnbvet platform are certified veterinarians from developed market, all of them are licensed veterinarians in Hong Kong ',
    },
    experience: {
      title: 'Book an appointment now ',
      when: 'When do you need bnbvet？',
      when_cart: {
        0: {
          0: {
            title: 'Pets with chronic illnesses',
            body: 'Requires veterinarians for long-term health observation and consultation services',
          },
          1: {
            title: 'Pets are overwhelmed by emergencies',
            body: 'Want to know the reason immediately with a professional vet',
          },
        },
        1: {
          0: {
            title: 'Nutritional and behavioral problems in pets ',
            body: 'Can\'t find professional information and need help',
          },
          1: {
            title: 'The treatment plan issued by the veterinary hospital',
            body: 'Want to hear more professional veterinary advice',
          },
        },
        2: {
          0: {
            title: 'Medicine delivery ',
            body: 'Need veterinary prescription, fast delivery to your home',
          },
          1: {
            title: 'Follow up ',
            body: 'Want a follow-up plan for diagnosis and treatment results',
          },
        },
        3: {
          0: {
            title: 'Emergency support ',
            body: 'Need quick emergency support & immediate professional advice',
          },
          1: {
            title: 'Health information',
            body: 'Subscribe for a comprehensive understanding of pet health information',
          },
        },
      },
    },
    process: {
      title: 'Online Appointment Procedure ',
      list: {
        0: {
          title: '01',
          head: 'Make an appointment',
          body: 'Choose the right veterinarian and make an appointment directly ',
        },
        1: {
          title: '02',
          head: 'Pay on the platform ',
          body: 'Receive video link immediately after payment',
        },
        2: {
          title: '03',
          head: 'Video meeting ',
          body: '24h meeting with our professional vet',
        },
        3: {
          body: 'Pay after selecting language and pet, and the order will be sent immediately',
        },
        4: {
          head: 'Veterinary Orders',
          body: 'Appointment for veterinarians to take orders, and no one takes orders for a full refund',
        },
      },
    },
    reserve: {
      title: 'Get trusted advice with bnbvet\'s professional veterinary',
      cart: {
        0: {
          title: 'Quick Consulation ',
          time: '15 mins',
          content: 'Instant consultation ',
          footer: 'Ask a vet for immediate advice',
        },
        1: {
          title: 'Schedule Appointment',
          time: '15-30 mins',
          content: 'Make an appointment',
          footer: 'Choose your preferred vet and time',
        },
      },
    },
    subscribe: {
      title: 'Subscribe bnbvet ',
      content: 'Sign up with email to get the latest news and information from bnbvet',
      btn: 'Subscribe',
      again: 'Wrong format, please input again !',
      confirm: 'Confirm',
    },
    customer: 'Contact Us : ',
  },
  about: {
    banner: {
      title: 'From online video consultations to private veterinary consultants',
      content: 'A platform for pet owners to raise pets at ease ',
      btn1: 'Get started',
    },
    content: {
      title: ' Introduction to bnbvet',
      title1: 'From online video consultations to private veterinary consultants ',
      title2: 'A platform for pet owners to raise pets at ease ',
      title3: 'From basic health to quality life',
      title4: 'O2O Health Strategies for Pets',
      content1: 'bnbvet provides vets and clinics with the necessary digital infrastructure to embrace the post pandemic online shift, so that they can focus on the medicals, not the hassle of operation.',
      content2: 'Pet parents can speak to bnbvet’s professional veterinarians and get trusted advice in the comfort of your home via virtual consultation.',
      satisfaction: 'customer satisfaction',
      media_interview: 'Media Interview',
      cooperation_list: 'Offline Cooperation List',
    },
  },
  expert: {
    banner: {
      title: 'Together with bnbvet ',
      content: 'Keeping pets easy and simple, let companionship last longer ',
      btn1: 'Get started',
    },
    title: 'Veterinary Team ',
    content1: 'Unlike veterinarians who do not have professional pet medical knowledge abroad ',
    content2: 'On bnbvet, 100% of the veterinarians are certified veterinarians from developed countries, all of whom are licensed veterinarians in Hong Kong ',
  },
  service: {
    banner: {
      title: ' Wholehearted service',
      content: 'International veterinary team provides 24-hour advice ',
      btn1: 'Get started',
    },
    title: 'Why is it so hard to see a vet ! ',
    content: '<ul><li>Going to the veterinary hospital requires long traffic and waiting times, and causes great psychological stress on pets and pet owners <br/>\
                    </li><li>The veterinary hospital is not available 24/7, and it takes weeks to make an appointment with a reputable veterinary hospital <br/>\
                    </li><li>Can\'t find a more professional veterinarian to ask whether the inspection report issued by the pet hospital is reasonable <br/>\
                    </li><li>Worried that pet hospitals exaggerate their illnesses and make consultations expensive</li></ul>',
    easy: 'Leave it to us, it\'s easy to see a vet',
    cart: {
      0: {
        title: 'Veterinarians only accept video consultations',
        content: 'Full 1V1 online veterinary service to ensure you get real expert advice',
      },
      1: {
        title: '24 hours remote consultation service ',
        content: 'Even if your pet has problems at night, Hong Kong veterinarians in the UK and  US can help you with video ',
      },
      2: {
        title: 'All veterinarians on the platform are professional Hong Kong practising veterinarians ',
        content: 'Transparent service fees, guaranteed medical ethics and service quality',
      },
      3: {
        title: 'Inspection reports can be uploaded to the bnbvet platform ',
        content: 'During the video consultation, your dedicated veterinary expert will advise you ',
      },
    },
    want_to_know: {
      title: 'Maybe you want to know ',
      list: {
        0: {
          title: 'How to refund the advance payment？',
          content: 'Within 24 hours from the agreed consultation time, we will charge 100% of the fee <br/>\
            24-48 hours from the agreed consultation time, we will charge 50% of the fee <br/>\
            More than 48 hours from the agreed consultation time, you will get a 100% refund ',
        },
        1: {
          title: 'When can I use video consultation for my pet?',
          content: 'Video consultation is suitable for general pet care questions, second opinions, chronic disease management and follow-ups, nutritional advice, behavioural issues and triaging medical conditions.<br/>\
                                  This will save the pets’ stressful journey to the clinic and minimize the risk of aggravating their health conditions due to the travelling.',
        },
        2: {
          title: 'Does my pet need to be present during the video consultation?',
          content: 'We recommend your pet to be present during the video consultation for the vet to make the best judgement possible. <br/>\
                                  Yet, your pet’s presence may not be necessary if your pet is hospitalized or you are only looking for general advice.',
        },
        3: {
          title: 'What if I want a definitive diagnosis for my pet?',
          content: 'A video consultation is not a replacement for physical consultation and a definitive diagnosis may not be reached. <br/>\
                      The vet will recommend you visit the clinic for physical examination and further diagnostic tests for a definitive diagnosis if necessary.',
        },
        4: {
          title: 'What do I need to prepare before the video consultation?',
          content: 'If you are using bnbvet for obtaining a second opinion, please prepare your pet’s previous medical histories, laboratory reports, diagnostic images and a list of all current medications and supplements at least 24 hours before the video consultation for the vet’s review. <br/>\
                      You may either provide these information or documents through the vet’s clinic or upload them through bnbvet.',
        },
        5: {
          title: 'I want to confirm my receipt, where can I find it?',
          // content: 'Once the payment is completed and the appointment time has passed, it can be downloaded. Click the 【Appointment】button in the bottom right corner to enter 【My Appointment】, find the 【Historical Order】 you want to query, click to enter the details page, and click 【Receipt】 in the upper right corner to download and save.',
          content: 'Please contact our customer service through WhatsApp.',
        },
        6: {
          title: 'What payment methods are accepted?',
          content: 'We accept a variety of payment methods to facilitate the needs of different customers: Paypal, Apple Pay, WeChat Pay, Alipay.',
        },
      },
    },
  },
  contactus: {
    banner: {
      title: 'Join bnbvet Veterinary Network ',
      content: 'Grow Your Online Veterinary Business ',
      btn1: 'Get started',
    },
    title: 'Welcome veterinarians to join bnbvet ',
    content: 'Provides you with a digital infrastructure and customers that give you 100% control over when and where you do business ',
    join_in: 'All Hong Kong veterinarians who hold a practising license <br /> issued by the Hong Kong Veterinary Authority can join!',
    cart: {
      0: {
        title: '100% freedom ',
        content1: 'Whether you are at home or in a clinic, you can receive consultation anytime, anywhere ',
      },
      1: {
        title: 'Expand your customer base ',
        content1: 'Access to clients in other regions, and cross-border cases',
      },
      2: {
        title: 'Modern digital operations ',
        content1: 'Easy access to pet case information, appointments, timetables, consultation and transactions online',
      },
    },
    attention: 'Thanks for attention ',
    content2: 'Whether you have valuable opinions on bnbvet, or want to cooperate with bnbvet',
    content3: 'You are welcome to contact us through the following methods, thank you for your attention to bnbvet!',
    address: 'Address ',
    address2: 'Room 54, Entrepreneurship Centre, Level 5, Core F, Cyberport 3',
    phone: 'Hotline ',
    email: 'Email Address ',
    wechatid: 'Wechat ID',
  },
  reserve: {
    title: 'My Appointments ',
    online: 'Online Appointment ',
    time: 'Appointment Time ',
    created_time: 'Order Time ',
    no_time: 'Waiting for doctor\'s order',
    code: 'Order Number',
    name: 'Pet’s Name ',
    doctor: 'Vet ',
    type: 'Method of visiting a vet ',
    price: 'Consultation Fee ',
    status: 'Appointment Status',
    1: 'Unpaid ',
    2: 'Paid ',
    3: 'Vet Connected ',
    4: 'Completed ',
    '-1': 'Cancelled ',
    '-2': 'Payment Error',
    '-3': 'Expired',
    '-4': 'Refund Failed',
    detail: {
      title: 'Appointment Details ',
      upload: 'Upload Information',
      reservation: 'Appointment Information ',
      veterinarian: 'Veterinarian ',
      date: 'Appointment Time ',
      method: 'Video Tool ',
      pet: 'Pet’s Name ',
      reason: 'Reason for consultation',
      save: 'Save',
      saveSuccess: 'Save Successfully',
      appointment: 'Reservation No. ',
      meeting: 'Start Video Conference ',
      comment1: 'Doctor\'s advice ',
      comment2: 'Past Prescription ',
      documents: 'User-uploaded Documents ',
      uploadSuccess: 'Uploaded Successfully ',
      surgery_report: 'Surgery Report ',
      prescription_medicine_applies: 'Prescription ',
      laboratory_record_list: 'Laboratory Records',
      diagnostic_record_list: 'Records ',
      buynow: 'Buy Now',
      quantity: 'Quantity',
      spec: 'Spec',
      order_list: 'Orders',
      order_time: 'Order Time',
      order_code: 'Order Number',
      order_remark: 'Order Remark',
      order_goods: 'Order Goods',
      shipping_address: 'Shipping Address',
      shipping_phone: 'Phone',
      shipping_name: 'Consignee',
      shipping_email: 'Email',
      status: 'Status',
      ucher_amount: 'Discount Amount',
      voucher_code: 'Coupon',
      ERROR: 'Payment Error',
      CANCELLED: 'Canceled',
      SUBMIT: 'Unpaid',
      PAID: 'Paid',
      SHIPPING: 'Shipping',
      DONE: 'Done',
      time: 'Time',
      category: 'Category',
      description: 'Description',
      file: 'File',
      StarRating: 'Star Rating',
    },
    cart: {
      title: 'Medicine List',
      title2: 'Confirm Order',
      buynow: 'Buy Now',
      total: 'Total',
      address: 'Address',
      orderinfo: 'Order Info',
      addressTip: 'Please fill in the receiving address',
      addAddress: 'Add Address',
      editAddress: 'Edit Address',
      save: 'Save',
      cancel: 'Cancel',
      saveSuccess: 'Successfully Saved ',
      freight: 'Freight',
      del: 'Delete',
      confirmDel: 'Confirm Delete ?',
      confirm: 'Confirm',
    },
  },
  member: {
    title: 'Personal information ',
    edit: 'Edit ',
    header: 'Avatar ',
    confirm: 'Save ',
    cancel: 'Cancel ',
    saveSuccess: 'Successfully saved ',
  },
  pet: {
    title: 'My Pets ',
    title2: 'Add Pet ',
    title3: ' Edit Pet Profile',
    enter: 'Please enter',
    header: 'Avatar ',
    add: 'Add',
    edit: 'Edit',
    del: 'delete',
    name: 'Pet’s Name',
    pettype: 'Pet Type ',
    birthday: 'Date of Birth ',
    neutered: 'Spayed/ Neutered? ',
    gender: 'Gender',
    breed: 'Pet Breed',
    blood: 'Blood Type',
    weight: 'Weight',
    yes: 'Yes',
    no: 'No',
    unknown: 'Unknown',
    confirmDel: 'Are you sure you want to delete? ',
    confirm: 'Save',
    cancel: 'Cancel',
    cat: 'Cat',
    dog: 'Dog',
    exotic: 'Exotic Pet ',
    male: 'Male',
    female: 'Female',
    addSuccess: 'Add Success ',
    saveSuccess: 'Successfully Saved ',
  },
  notice: {
    title: 'Notification List',
    nothing: 'Nothing Notice',
  },
  booking: {
    title: 'Choose Time',
    title2: 'Choose Vet',
    title3: 'Choose A Doctor ',
    refresh: 'Refresh',
    within_30_mins: 'Within 30 mins',
    later_time: 'Choose a later time',
    pet: 'Pet ',
    voucher: 'Promo Code ',
    consultingfee: 'Consulting Fee ',
    service: 'Service Charge ',
    subtotal: 'Total ',
    apply: 'Apply ',
    select_pet: 'Please choose a pet!',
    select_time: 'Please choose a time!',
    input_voucher: 'Please enter promo code!',
    paytype: 'Payment Method ',
    paynow: 'Pay Now ',
    cancel: 'Cancel',
    longtime: 'Duration ',
    30: '30mins',
    15: '15mins',
    area: 'Region ',
    lang: 'Language ',
    date: 'Date',
    timeType: 'Period ',
    price: 'Price ',
    assign: 'Designated Veterinarian ',
    moreo_ptions: 'More options for veterinary time ',
    fully_booked: 'Fully booked at the moment, please choose another vet',
    lang1: 'Mandarin ',
    lang2: 'Cantonese ',
    lang3: 'English ',
    region1: 'Hong Kong',
    region2: 'Other Regions ',
    all: 'All',
    todate: 'Today',
    date10: 'Last 10 Days ',
    time1: 'Morning ',
    time2: '下午',
    time3: 'Afternoon ',
    400: '400-500 Dollars ',
    500: '500-600 Dollars ',
    600: '600-700 Dollars ',
    search: 'Search ',
    sort: {
      time: 'Time - closest on top',
      seniority: 'Seniority - low to high',
      price: 'Price - low to high',
      time2: 'Time Reverse Sort',
      seniority2: 'Seniority - high to low',
      price2: 'Price - high to low',
    },
    table: {
      name: 'Vet\'s Name ',
      seniority: 'Years of Service ',
      education: 'Education',
      interest: 'Area of Interest ',
      price: 'Price',
      type: 'Type ',
      support_region: 'Support Region',
      support_language: 'Support Language',
      pet_kind_name: 'Species of Interest',
      year: ' Year',
      starRating: 'Star Rating',
    },
    doctor_time: 'Availability ',
    doctor_date: 'Appointment Date',
    other_date: 'Other available time slots',
    comprehensive: 'Comprehensive ',
    total: 'Total ',
    discountAmount: 'Discount Amount',
    doctorTotal: ' doctor(s) ',
    confirmDoctor: 'Veterinarian ',
    confirmTime: 'Appointment Time ',
    confirmPrice: 'Consulting Fee ',
    confirmServiceFee: 'Service Charge ',
    confirmTotalPrice: 'Total Price ',
    prompt: 'Friendly Reminder ',
    promptText: 'You will not be charged unless you are successfully matched with an available vet ',
    doctorInfo: 'Vet Details ',
    takingOrders: 'Doctor Taking Orders',
    minute: 'Minute',
    invoice: 'Download Receipt',
    invoiceTip: 'Receipt will be ready after the consultantation',
  },
  payment: {
    title: ' Pay ',
    1: 'Select Payment Method ',
    2: 'Date',
    3: 'Time',
    4: ' Method of visiting a vet ',
    5: 'Online medical treatment ',
    6: 'Pay',
    7: 'Total price',
    8: 'We\'ll jump to PayPal.',
    9: 'Do not close the webpage before completing the payment. The system needs a certain period to receive notifications from third-party payment providers. ',
    10: 'Pay now ',
    11: 'This is the last step, please click the payment button to pay ',
    12: 'Illustrate ',
    13: '',
    20: 'If you have a voucher, please enter the voucher number ',
    21: 'Submit',
    22: 'Voucher Number ',
    23: 'Voucher Amount ',
    100: 'Payment Not Completed ',
    101: 'Confirm ',
    102: 'Payment Completed ',
    103: 'Confirm',
    104: 'Jumping to payment page...',
    105: 'Payment error, please use another payment method ',
    106: 'Your payment is now under review',
    107: 'Payment Under Review ',
  },
  meeting: {
    title: ' ',
    message: 'Message ',
    btntext: 'Send ',
    downloadFile: 'Open the file?',
    start: 'Start a video call',
  },
};
