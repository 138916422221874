module.exports = {
  error: '錯誤',
  tips: '提示',
  hotline: '客服電話',
  deleteAccount: '刪除帳戶',
  deleteAccountTip: '您確定要刪除此帳戶嗎？ 刪除帳戶將清除所有數據！',
  subscribeSuccess: '訂閱成功！',
  previousPage: '上一頁',
  nextPage: '下一頁',
  message: '請在此留言，我們的客服人員會很快聯繫您',
  contactInformation: '聯繫方式',
  messageSuccess: '留言成功！',
  messageError: '請提供聯絡方式',
  calendar: {
    mon: '壹',
    tues: '貳',
    wed: '三',
    thur: '肆',
    fri: '伍',
    sat: '陸',
    sun: '日',
    year: '年',
    month: '月',
    day: '日',
    monday: '星期壹',
    tuesday: '星期貳',
    wednesday: '星期三',
    thursday: '星期肆',
    friday: '星期伍',
    saturday: '星期陸',
    sunday: '星期日',
  },
  navbar: {
    index: 'bnbvet',
    home: '首頁',
    about: '關於bnbvet',
    expert: '專家團隊',
    service: '常見問題',
    petstory: '寵物故事',
    media: '媒體報道',
    contact: '聯系我們',
    reserve: '在線預約',
    login: '登錄',
    signup: '註冊',
    myreserve: '我的預約',
    myinfo: '個人信息',
    mypet: '我的寵物',
    notice: '通知列表',
    logout: '退出登錄',
  },
  tabbar: {
    home: '首頁',
    doctor: '醫生',
    member: '會員中心',
    pets: '寵物',
    bookings: '預約',
    lang: '語言切換',
  },
  login: {
    title: '歡迎登錄bnbvet',
    title2: '歡迎註冊bnbvet',
    title3: '重置密碼',
    email: '郵箱地址',
    phone: '手機號碼',
    username: '用戶名',
    password: '密碼',
    password2: '重復密碼',
    signnow: '登錄',
    signed: '注冊成功！',
    logged: '登錄成功！',
    remember: '記住我',
    iaccept: '我接受',
    disclaimer: '免責聲明',
    loginsuccessful: '登錄成功',
    confirm: '確定',
    cancel: '取消',
    signupnow: '郵箱註冊',
    loginnow: '郵箱登錄',
    forgot: '忘記密碼',
    signup: '註冊',
    already: '已發送',
    verifycode: '驗證碼將會被發往您的郵箱',
    getverifycode: '獲取郵箱驗證碼',
    tipstext: '驗證碼發送成功！',
    success: '成功！',
    reset: '重置',
    new: '新',
    logout: '退出登錄',
  },
  home: {
    banner: {
      title: '安坐家中',
      content: '與專業獸醫團隊視頻會面',
      btn1: '寵物咨詢',
    },
    industry: {
      service: '寵物視頻關愛服務',
      veterinarian: '執業獸醫',
      education: '獸醫學發達市場認證學歷',
      satisfaction: '客戶滿意度',
    },
    why_choose: {
      title: '為什麼選擇bnbvet？',
      bnbvet: 'bnbvet',
      btn: '立即體驗服務',
      box1: {
        title: '輕松快捷，安在家中',
        content1: '寵物暢享24h視頻關愛',
        content2: '自主掌握寵物健康',
      },
      box2: {
        title: '執業獸醫，僅限視頻咨詢',
        content1: '100%發達市場認證學歷',
        content2: '全程1對1獸醫在線服務',
      },
    },
    veterinary_team: {
      title: '獸醫團隊',
      directions1: '與沒有得到過國外專業寵物醫學培養的獸醫不同',
      directions2: 'bnbvet平臺上的獸醫，100%為發達市場認證學歷，均為執業獸醫',
    },
    experience: {
      title: '立即體驗服務',
      when: '你什麼時候需要bnbvet？',
      when_cart: {
        0: {
          0: {
            title: '寵物長期病患',
            body: '需要獸醫進行長期健康觀察與咨詢服務',
          },
          1: {
            title: '寵物突發事件不知所措',
            body: '想要馬上和專業獸醫了解下原因',
          },
        },
        1: {
          0: {
            title: '寵物出現營養、行為等問題',
            body: '找不到專業資料，需要得到幫助',
          },
          1: {
            title: '對於寵物醫院已出具的治療方案',
            body: '想聽聽更專業的獸醫建議',
          },
        },
        2: {
          0: {
            title: '藥物配送',
            body: '需要獸醫配藥，快捷送達你手中',
          },
          1: {
            title: '診後跟進',
            body: '想要諮詢效果跟進計劃',
          },
        },
        3: {
          0: {
            title: '緊急支援',
            body: '需要快速緊急支援，即時的專業建議',
          },
          1: {
            title: '健康資訊',
            body: '訂閱資訊，全面了解寵物健康資訊',
          },
        },
      },
    },
    process: {
      title: '在線預約流程',
      list: {
        0: {
          title: '01',
          head: '預約獸醫',
          body: '選擇合適獸醫，直接預約時間',
        },
        1: {
          title: '02',
          head: '平臺付款',
          body: '付款後，立即收到視頻服務鏈接',
        },
        2: {
          title: '03',
          head: '視頻會面',
          body: '24h與我們的專業獸醫會面',
        },
        3: {
          body: '選擇語言和寵物後進行付款，訂單立即發出',
        },
        4: {
          head: '獸醫接單',
          body: '可預約的值班獸醫接單，如果無人接單會全額退款',
        },
      },
    },
    reserve: {
      title: '與bnbvet的專業獸醫視頻，獲得值得信賴的建議',
      cart: {
        0: {
          title: '實時服務',
          time: '15分鐘',
          content: '即時諮詢',
          footer: '立即向獸醫問取意見',
        },
        1: {
          title: '預約服務',
          time: '15-30分鐘',
          content: '預約諮詢',
          footer: '選擇您喜歡的獸醫和時間',
        },
      },
    },
    subscribe: {
      title: '訂閱bnbvet',
      content: '使用電郵註冊，獲取bnbvet最新消息及資訊',
      btn: '訂閱',
      again: '格式有誤，請再次輸入!',
      confirm: '確定',
    },
    customer: '聯絡我們： ',
  },
  about: {
    banner: {
      title: '從在線視頻咨詢到<br/>私人獸醫顧問',
      content: '為主人而設的安心養寵平臺',
      btn1: '寵物問題咨詢',
    },
    content: {
      title: 'bnbvet簡介',
      title1: '從在線諮詢到私人獸醫顧問',
      title2: '為主人而設的安心養寵平臺',
      title3: '從基礎健康到優質生活',
      title4: '為寵物而設的O2O健康策略',
      content1: 'BnBVet為獸醫和診所提供必要的數碼化基礎設備，以迎接疫情後的在線服務轉變。 獸醫可因此更專註於提供醫療服務，省卻於行政操作上的費時勞神。',
      content2: '寵物父母也可安在家中與BnBVet的專業獸醫溝通，通過線上咨詢獲得值得信賴的建議。',
      satisfaction: '客戶滿意度',
      media_interview: '媒體采訪',
      cooperation_list: '線下合作診所清單',
    },
  },
  expert: {
    banner: {
      title: '和bnbvet一起',
      content: '更安心地飼養寵物，讓陪伴更長久',
      btn1: '寵物問題咨詢',
    },
    title: '獸醫團隊',
    content1: '與沒有得到過國外專業寵物醫學培養的獸醫不同',
    content2: 'bnbvet平臺上的獸醫，100%為發達國家認證學歷，均為執業獸醫',
  },
  service: {
    banner: {
      title: '全心服務',
      content: '國際獸醫團隊24h提供建議',
      btn1: '寵物問題咨詢',
    },
    title: '寵物看病為什麼這麼難！',
    content: '<ul><li>去寵物醫院需要漫長的交通和等待時間，並且對寵物和寵物主人造成極大心理壓力<br/>\
                  </li><li>寵物醫院並非24小時全天候應診，並且信譽良好的寵物醫院需要數周前預約<br/>\
                  </li><li>對於寵物醫院出具的檢查報告，找不到更專業的獸醫咨詢報告是否合理<br/>\
                  </li><li>擔心寵物醫院小病大治，價格昂貴</li></ul>',
    easy: '交給我們，寵物看病很簡單',
    cart: {
      0: {
        title: '獸醫只接受視頻咨詢',
        content: '獸醫全程1V1在線服務，確保您獲取真實專家建議的權益',
      },
      1: {
        title: '遠程24小時咨詢服務',
        content: '即使深夜寵物出現問題，都可以給予你視頻幫助',
      },
      2: {
        title: '平臺均為專業執業獸醫',
        content: '服務費用透明，醫療道德及服務質量有保障',
      },
      3: {
        title: '檢查報告可以上傳至bnbvet平臺',
        content: '在視頻咨詢時，你的專屬獸醫專家會給予建議',
      },
    },
    want_to_know: {
      title: '可能你還想知道',
      list: {
        0: {
          title: '預付款如何安心退？',
          content: '距離約定咨詢時間24小時內，我們將收取100%的費用<br/>\
                                距離約定咨詢時間24-48小時，我們將收取50%的費用<br/>\
                                距離約定咨詢時間48小時以上，您將獲得100%的退款',
        },
        1: {
          title: '何時能夠為寵物進行視像咨詢？',
          content: '視像諮詢適用於一般寵物護理問題、第二醫療意見、慢性疾病管理及跟進、營養指導、行為異常問題及醫療狀況分類。<br/>\
                                藉此方法能夠省卻寵物前往診所的負擔，並減低寵物於旅途中惡化病情的風險。',
        },
        2: {
          title: '我的寵物必須要出席視像咨詢嗎？',
          content: '為確保獸醫團隊能夠作出最佳診斷，我們建議顧客攜同寵物一同進行視像諮詢。<br/>\
                                然而，假若你的寵物正在住院或你只是尋求一般意見，則無須帶同寵物出席。',
        },
        3: {
          title: '要是我需為寵物進行明確的診斷，該怎麼辦？',
          content: '視像諮詢並未能替代身體檢查或作出決定性診斷。<br/>\
                    如有需要，我們的獸醫或將建議你帶同寵物到診所，以作身體評估及進一步的診斷測試。',
        },
        4: {
          title: '我於視像咨詢前有什麼需要準備？',
          content: '如你使用bnbvet是以獲取第二醫療意見，請於視像諮詢開始前最少24小時，為你的寵物準備過往的醫療紀錄、化驗報告、醫學影像以及現時服用藥物或補充品等資料，以供我們參考。<br/>\
                    你可透過獸醫診所提供以上資料及文件，或選擇於bnbvet上載。',
        },
        5: {
          title: '我想要確認我的收據，該去哪裏查詢好呢？',
          content: '請聯絡我們的客服人員。',
        },
        6: {
          title: '你們接受什麼付款方式？',
          content: '我們接受多種支付方式，方便不同客人需要：Paypal、Apple Pay、微信支付、支付寶。',
        },
      },
    },
  },
  contactus: {
    banner: {
      title: '加入bnbvet獸醫網絡',
      content: '開拓你的網上獸醫業務',
      btn1: '寵物問題咨詢',
    },
    title: '歡迎獸醫加入bnbvet',
    content: '為您提供數碼基礎設施和客戶，讓您100%控製何時何地開展您的業務',
    join_in: '所有持有獸醫管理局頒發的執業許可證的獸醫均可加入！',
    cart: {
      0: {
        title: '100%的自由',
        content1: '不管你是在家裏還是診所，隨時隨地接受咨詢',
      },
      1: {
        title: '擴大您的客戶群',
        content1: '接觸跨越地域的客戶，以及跨境個案',
      },
      2: {
        title: '現代數碼化營運',
        content1: '在線上輕松獲取寵物個案的資料、預約、時間表，進行咨詢和交易',
      },
    },
    attention: '感謝關註',
    content2: '無論您是對bnbvet有寶貴意見，還是想與bnbvet合作共贏',
    content3: '都歡迎通過以下方式聯系我們，感謝您對bnbvet的關註和喜愛！',
    address: '地址',
    address2: '數碼港3座F區5樓企業發展中心54號',
    phone: '客服電話',
    email: '郵箱',
    wechatid: '微信号',
  },
  reserve: {
    title: '我的預約',
    online: '在線預約',
    time: '預約時間',
    created_time: '訂單時間',
    no_time: '等待醫生接單中',
    code: '訂單編號',
    name: '寵物名稱',
    doctor: '預約醫生',
    type: '就診方式',
    price: '就診費用',
    status: '預約狀態',
    1: '未支付',
    2: '已支付',
    3: '獸醫已接單',
    4: '已完成',
    '-1': '已取消',
    '-2': '支付錯誤',
    '-3': '已失效',
    '-4': '退款失敗',
    detail: {
      title: '預約詳情',
      upload: '上傳資料',
      reservation: '預約信息',
      veterinarian: '預約獸醫',
      date: '預約時間',
      method: '視頻工具',
      pet: '寵物名稱',
      reason: '諮詢原因',
      save: '保存',
      saveSuccess: '保存成功',
      appointment: '預約單號',
      meeting: '開啟視頻會議',
      comment1: '醫生建議',
      comment2: '過往處方',
      documents: '用戶上傳的文檔',
      uploadSuccess: '上傳成功',
      surgery_report: '手術報告',
      prescription_medicine_applies: '處方藥',
      laboratory_record_list: '檢測歷史記錄',
      diagnostic_record_list: '醫療記錄',
      buynow: '立即購買',
      quantity: '數量',
      spec: '規格',
      order_list: '藥品訂單',
      order_time: '訂單時間',
      order_code: '訂單編號',
      order_remark: '訂單備註',
      order_goods: '訂單商品',
      shipping_address: '收貨地址',
      shipping_phone: '收貨電話',
      shipping_name: '收貨人',
      shipping_email: '收貨郵箱',
      status: '狀態',
      ucher_amount: '優惠金額',
      voucher_code: '優惠券',
      ERROR: '支付錯誤',
      CANCELLED: '已取消',
      SUBMIT: '未支付',
      PAID: '已支付',
      SHIPPING: '配送中',
      DONE: '已完成',
      time: '時間',
      category: '類別',
      description: '描述',
      file: '文件',
      StarRating: '星級評分',
    },
    cart: {
      title: '配药列表',
      title2: '確認訂單',
      buynow: '立即购买',
      total: '总额',
      address: '收件地址',
      addressTip: '請填寫收件地址',
      orderinfo: '订单信息',
      addAddress: '增加地址',
      editAddress: '編輯地址',
      save: '保存',
      cancel: '取消',
      saveSuccess: '保存成功',
      freight: '運費',
      del: '刪除',
      confirmDel: '是否刪除',
      confirm: '確定',
    },
  },
  member: {
    title: '個人信息',
    edit: '編輯',
    header: '頭像',
    confirm: '保存',
    cancel: '取消',
    saveSuccess: '保存成功',
  },
  pet: {
    title: '我的寵物',
    title2: '新增寵物',
    title3: '編輯寵物',
    enter: '請輸入',
    header: '頭像',
    add: '新增',
    edit: '編輯',
    del: '刪除',
    name: '寵物名稱',
    pettype: '寵物類型',
    birthday: '出生年份',
    neutered: '是否絕育',
    gender: '寵物性別',
    breed: '品種',
    blood: '血型',
    weight: '重量',
    yes: '是',
    no: '否',
    unknown: '未知',
    confirmDel: '是否刪除',
    confirm: '保存',
    cancel: '取消',
    cat: '貓',
    dog: '狗',
    exotic: '異寵',
    male: '公',
    female: '母',
    addSuccess: '新增成功',
    saveSuccess: '保存成功',
  },
  notice: {
    title: '通知列表',
    nothing: '暫無通知',
  },
  booking: {
    title: '選時間',
    title2: '選醫生',
    title3: '選擇醫生',
    refresh: '刷新',
    within_30_mins: '30分鐘之內',
    later_time: '選擇將來的時間',
    pet: '寵物',
    voucher: '優惠碼',
    consultingfee: '諮詢費',
    service: '服務費',
    subtotal: '總額',
    apply: '使用',
    select_pet: '請選擇寵物！',
    select_time: '請選擇時間！',
    input_voucher: '請輸入優惠碼！',
    paytype: '支付方式',
    paynow: '立即支付',
    cancel: '取消',
    longtime: '時長',
    30: '30分鍾',
    15: '15分鍾',
    area: '地區',
    lang: '語言',
    date: '日期',
    timeType: '時段',
    price: '價格',
    assign: '指定獸醫',
    moreo_ptions: '更多選擇獸醫日期時間',
    fully_booked: '暫無時間可以預約，請選擇其他獸醫',
    lang1: '普通話',
    lang2: '粵語',
    lang3: '英語',
    region1: '香港',
    region2: '其他地區',
    all: '全部',
    todate: '今天',
    date10: '最近10天',
    time1: '上午',
    time2: '下午',
    time3: '半夜',
    400: '400-500元',
    500: '500-600元',
    600: '600-700元',
    search: '搜索',
    sort: {
      time: '時間 - 由近至遠',
      seniority: '年資 - 由低到高',
      price: '價格 - 由低到高',
      time2: '時間 - 由遠至近',
      seniority2: '年資 - 由高到低',
      price2: '價格 - 由高到低',
    },
    table: {
      name: '獸醫姓名',
      seniority: '年資',
      education: '學歷',
      interest: '興趣領域',
      price: '價格',
      type: '獸醫類型',
      support_region: '支持區域',
      support_language: '支持語言',
      pet_kind_name: '支持寵物',
      year: '年',
      starRating: '星級評分',
    },
    doctor_time: '預約時間',
    doctor_date: '預約日期',
    other_date: '其他可選時間',
    comprehensive: '綜合型',
    total: '共',
    discountAmount: '優惠額',
    doctorTotal: '名醫生',
    confirmDoctor: '預約獸醫',
    confirmTime: '預約時間',
    confirmPrice: '諮詢費用',
    confirmServiceFee: '服務費',
    confirmTotalPrice: '總額',
    prompt: '溫馨提示',
    promptText: '除非您與可試用的獸醫成功匹配，否則不會向您收取費用',
    doctorInfo: '醫生詳情',
    takingOrders: '醫生接單中',
    minute: '分鐘',
    invoice: '下載收據',
    invoiceTip: '收據將在咨詢結束後生成',
  },
  payment: {
    title: '支付',
    1: '選擇支付方式',
    2: '日期',
    3: '時間',
    4: '就診方式',
    5: '綫上就診',
    6: '支付',
    7: '總額',
    8: '我們將會跳轉到 PayPal.',
    9: '完成支付前請勿關閉網頁. 系統需要一定時間接收第三方支付商通知. ',
    10: '立即支付',
    11: '這是最後一步, 請點擊付款按鈕進行支付',
    12: '說明',
    13: '',
    20: '如有代金券，請輸入代金券編號',
    21: '提交',
    22: '代金券編號',
    23: '代金券金額',
    100: '付款未完成',
    101: '確認',
    102: '付款完成',
    103: '確認',
    104: '正在跳轉支付頁面...',
    105: '支付錯誤，請使用其他支付方式',
    106: '支付信息核實中',
    107: '支付核實中',
  },
  meeting: {
    title: '視頻診療',
    message: '消息',
    btntext: '發送',
    downloadFile: '打開文件嗎？',
    start: '開始視頻通話',
  },
};
